import { useEffect, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '../../../base/hooks/hooks';
import { setMessageInfo, setMessageNotify, setMessageType } from '../../../base/reducer/alertReducer';
import Toast from 'react-bootstrap/Toast';

function CustomAlert() {
    const { messageNotify, messageInfo, messageType } = useAppSelector((state: any) => state.alertReducer);
    const dispatch = useAppDispatch();

    const errorModelClose = useCallback(() => {
        setTimeout(() => {
            dispatch(setMessageNotify(false));
            dispatch(setMessageInfo(""));
            dispatch(setMessageType(""));
        }, 3000);
    }, [dispatch]);

    useEffect(() => {
        if (messageNotify) {
            errorModelClose();
        }
    }, [messageNotify, errorModelClose]);

    return (
        <Toast
               className={
                   messageType === 'success' ? 'bg-success'
                       : messageType === 'error' ? 'bg-danger'
                           : messageType === 'warning' ? 'bg-warning'
                               : ''
               }
               style={{
                   position: 'fixed',
                   top: 10,
                   right: 10,
                   zIndex: 9999
               }}
        >
            <Toast.Header></Toast.Header>
            <Toast.Body>{messageInfo}</Toast.Body>
        </Toast>
    );
}

export default CustomAlert;
