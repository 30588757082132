export const PATH ={
    LOGIN: '/',
    DASHBOARD : '/dashboard',
    NOT_FOUND:'/page-not-found',

    USER_LIST: '/user-list',
    USER_ADD: '/user/add-user',
    USER_TYPE: '/user/:type?/:id?',

    USER_LIST_PAGE: '/user-list-page',
    USER_LIST_PAGE_ADD: '/user-list-page-add',
    USER_LIST_PAGE_TYPE: '/user-list-page/:type?/:id?',

    USER_CONTACT_LIST: '/user-contact-list',
    USER_CONTACT_LIST_VIEW: '/user-contact-list-view',
    USER_CONTACT_LIST_ADD: '/user-contact-list-add',
    USER_CONTACT_LIST_TYPE: '/user-contact-list/:type?/:id?',

    USER_CONTACT_LABEL_SCREEN: '/user-contact-label-screen',
    USER_CONTACT_LABEL_SCREEN_ADD: '/user-contact-label-screen-add',
    USER_CONTACT_LABEL_TYPE: '/user-contact-label-screen/:type?/:id?',

    USER_RESET_PASSWORD: '/user-reset-password',
    USER_CONTACT_LIST_BY_ID: '/user-contact-list/view-contact/:id?',

    CONTACT_PROFILE_LIST: '/contact-profile',
    CONTACT_PROFILE_ADD: '/contact-profile/add-profile',
    CONTACT_PROFILE_TYPE: '/contact-profile/:type?/:id?',


}