export const urlConstants = {
    LOGIN: '/auth/login',
    ADMIN_LOGIN: '/auth/admin-login',
    USERLIST: '/users',
    ADMIN_USER_LIST: '/admin-user',
    ADMIN_USER_CONTACT: '/contacts',
    USER_DROP_DOWN: '/users/dropdown',
    CONTACT_LABEL: '/label',
    CONTACT_LABEL_DROPDOWN: '/label/dropdown',

    FORGOT_PASSWORD_SEND_OTP: '/users/forgot-password/send-otp',
    FORGOT_PASSWORD_VERIFY_OTP: '/users/forgot-password/verify-otp',
    FORGOT_PASSWORD_RESET_PASSWORD: '/users/forgot-password/reset-password',

    CONTACT_PROFILE: '/profile-type',
    CONTACT_PROFIEL_DROPDOWN: '/profile-type/dropdown',

    CONTACT_LOCATION: '/contacts/locations',

    ADMIN_DASHBOARD: '/admin-user/dashboard'
}