import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type ErrorState = {
    unAuthorized: boolean;
    notFound: boolean;
    emailErrorMessage: string;
    passwordErrorMessage: string;
    passwordConfirmErrorMessage: string;
    nameErrorMessage: string;
    userErrorMessage: string;
    userLastNameErrorMessage: string;
    userEmailErrorMessage: string;
    mobileErrorMessage: string;
    companyErrorMessage: string;
    secondaryEmailErrorMessage: string;
    addressErrorMessage: string;
    locationErrorMessage: string;
    referredByErrorMessage: string;
    profileTypeErrorMessage: string;
    notesErrorMessage: string;
    contactVisibilityErrorMessage: string;
    ownerErrorMessage: string;
    otherUsersErrorMessage: string;
    contactLabelErrorMessage:string;
    contactLabelTitleErrorMessage: string;
    contactLabelDescriptionErrorMessage: string;
    contactProfileDescriptionErrorMessage: string;
    contactProfileNameErrorMessage: string;
}

const initialState: ErrorState = {
    unAuthorized: false,
    notFound: false,
    emailErrorMessage: '',
    passwordErrorMessage: '',
    nameErrorMessage: '',
    userErrorMessage: '',
    userLastNameErrorMessage: '',
    userEmailErrorMessage: '',
    mobileErrorMessage: '',
    companyErrorMessage: '',
    secondaryEmailErrorMessage: '',
    addressErrorMessage: '',
    locationErrorMessage: '',
    referredByErrorMessage: '',
    profileTypeErrorMessage: '',
    notesErrorMessage: '',
    contactVisibilityErrorMessage: '',
    ownerErrorMessage: '',
    otherUsersErrorMessage: '',
    contactLabelErrorMessage: '',
    contactLabelTitleErrorMessage: '',
    contactLabelDescriptionErrorMessage: '',
    passwordConfirmErrorMessage: '',
    contactProfileNameErrorMessage: '',
    contactProfileDescriptionErrorMessage: ''
}

const ErrorSlice = createSlice({
    name: 'errorMessage',
    initialState,
    reducers: {
        setNameErrorMessage: (state: ErrorState, action: PayloadAction<string>) => {
            state.nameErrorMessage = action.payload;
        },
        setUnAuthorized: (state: ErrorState, action: PayloadAction<boolean>) => {
            state.unAuthorized = action.payload;
        },
        setNotfound: (state: ErrorState, action: PayloadAction<boolean>) => {
            state.notFound = action.payload;
        },
        setEmailErrorMessage: (state: ErrorState, action: PayloadAction<string>) => {
            state.emailErrorMessage = action.payload;
        },
        setPasswordErrorMessage: (state: ErrorState, action: PayloadAction<string>) => {
            state.passwordErrorMessage = action.payload;
        },
        setPasswordConfirmErrorMessage: (state: ErrorState, action: PayloadAction<string>) => {
            state.passwordConfirmErrorMessage = action.payload;
        },
        setUserNameErrorMessage: (state: ErrorState, action: PayloadAction<string>) => {
            state.userErrorMessage = action.payload;
        },
        setUserLastNameErrorMessage: (state: ErrorState, action: PayloadAction<string>) => {
            state.userLastNameErrorMessage = action.payload;
        },
        setUserEmailErrorMessage: (state: ErrorState, action: PayloadAction<string>) => {
            state.userEmailErrorMessage = action.payload;
        },
        setMobileErrorMessage: (state: ErrorState, action: PayloadAction<string>) => {
            state.mobileErrorMessage = action.payload;
        },
        setCompanyErrorMessage: (state: ErrorState, action: PayloadAction<string>) => {
            state.companyErrorMessage = action.payload;
        },
        setSecondaryEmailErrorMessage: (state: ErrorState, action: PayloadAction<string>) => {
            state.secondaryEmailErrorMessage = action.payload;
        },
        setAddressErrorMessage: (state: ErrorState, action: PayloadAction<string>) => {
            state.addressErrorMessage = action.payload;
        },
        setLocationErrorMessage: (state: ErrorState, action: PayloadAction<string>) => {
            state.locationErrorMessage = action.payload;
        },
        setReferredByErrorMessage: (state: ErrorState, action: PayloadAction<string>) => {
            state.referredByErrorMessage = action.payload;
        },
        setProfileTypeErrorMessage: (state: ErrorState, action: PayloadAction<string>) => {
            state.profileTypeErrorMessage = action.payload;
        },
        setNotesErrorMessage: (state: ErrorState, action: PayloadAction<string>) => {
            state.notesErrorMessage = action.payload;
        },     
        setContactVisibilityErrorMessage: (state: ErrorState, action: PayloadAction<string>) => {
            state.contactVisibilityErrorMessage = action.payload;
        },
        setOwnerErrorMessage: (state: ErrorState, action: PayloadAction<string>) => {
            state.ownerErrorMessage = action.payload;
        },
        setOtherUsersErrorMessage: (state: ErrorState, action: PayloadAction<string>) => {
            state.otherUsersErrorMessage = action.payload;
        },
        setContactLabelErrorMessage: (state: ErrorState, action: PayloadAction<string>) => {
            state.contactLabelErrorMessage = action.payload;
        },
        setContactLabelTitleErrorMessage: (state: ErrorState, action: PayloadAction<string>) => {
            state.contactLabelTitleErrorMessage = action.payload;
        },
        setContactLabelDescriptionErrorMessage: (state: ErrorState, action: PayloadAction<string>) => {
            state.contactLabelDescriptionErrorMessage = action.payload;
        },
        setContactProfileNameErrorMessage: (state: ErrorState, action: PayloadAction<string>) => {
            state.contactProfileNameErrorMessage = action.payload;
        },
        setContactProfileDescriptionErrorMessage: (state: ErrorState, action: PayloadAction<string>) => {
            state.contactProfileDescriptionErrorMessage = action.payload;
        },


    }
})

export default ErrorSlice.reducer;

export const {
    setUnAuthorized,
    setNotfound,
    setEmailErrorMessage,
    setPasswordErrorMessage,
    setNameErrorMessage,
    setUserNameErrorMessage,
    setUserLastNameErrorMessage,
    setUserEmailErrorMessage,
    setMobileErrorMessage,
    setCompanyErrorMessage,
    setSecondaryEmailErrorMessage,
    setAddressErrorMessage,
    setLocationErrorMessage,
    setReferredByErrorMessage,
    setProfileTypeErrorMessage,
    setNotesErrorMessage,
    setContactVisibilityErrorMessage,
    setOwnerErrorMessage,
    setOtherUsersErrorMessage,
    setContactLabelErrorMessage,
    setContactLabelTitleErrorMessage,
    setContactLabelDescriptionErrorMessage,
    setPasswordConfirmErrorMessage,
    setContactProfileNameErrorMessage,
    setContactProfileDescriptionErrorMessage
} = ErrorSlice.actions;
